<template>
<div style="width: 100%; background-color: #003C8E; height: 100vh; display: flex; align-items:center; justify-content:center;">
      <v-card style="padding: 20px;">
        <div class="d-flex align-items-center justify-center" style="flex-direction: column;">

            <div v-if="stream" class=" justify-center items-center d-flex rounded-lg overflow-hidden" style="max-width: 900px;">
              <video
                :srcObject.prop="stream"
                autoPlay
                playsInline
                style="width: 100%;height: 100%; objectFit: contain;">
                <track kind="captions" />
              </video>
            </div>
            <div v-if="!isLoadingSession" class="h-full justify-center items-center flex flex-col gap-8 w-[500px] self-center">
              <div class="mt-2">
                <v-btn v-if="!stream" size="md" variant="shadow" @click="startSession">
                  Iniciar Sesión
                </v-btn>

                <v-btn v-if="stream" size="md" variant="shadow" @click="endSession">
                  Finalizar Sesión
                </v-btn>
              </div>
            </div>

            <v-progress-circular v-if="!stream && isLoadingSession" indeterminate color="primary"></v-progress-circular>
        </div>
        <v-divider v-if="stream"/>

    <v-tabs  v-if="stream" v-model="tab" centered slider-color="primary">
      <v-tab @click="handleChangeChatMode('voice_mode')" :key="'voice-mode'">Chat de Voz</v-tab>
      <v-tab @click="handleChangeChatMode('text_mode')" :key="'text-mode'">Chat de Texto</v-tab>
    </v-tabs>
    
    <v-tabs-items  v-if="stream" v-model="tab" class="d-flex align-center mt-5" >
      <v-tab-item :key="'voice_mode'">

      <v-btn color="primary" @click="handleSpeak" :disabled="isUserTalking">
          {{isUserTalking ? "Escuchando..." : "Hablar"}}
        <v-icon right dark>mdi-microphone</v-icon>
      </v-btn>
      </v-tab-item>
      <v-tab-item :key="'text_mode'">
        <div  style="width: 100%;">
          <div class="d-flex" style="gap:12px;">
            <v-text-field placeholder="Escribe tu pregunta" outlined v-model="text"></v-text-field>
            <v-btn  color="primary" @click="requestMessage" class="mt-2">Enviar</v-btn>
          </div>
        </div>
      </v-tab-item>
</v-tabs-items>
  </v-card>
    </div>
</template>

<script>
import  StartAvatarResponse  from "@heygen/streaming-avatar";
import StreamingAvatar, { AvatarQuality, StreamingEvents, TaskType, VoiceEmotion } from "@heygen/streaming-avatar";
import axios from 'axios'

export default {
    components: { StreamingAvatar, AvatarQuality, StreamingEvents, TaskType, VoiceEmotion, StartAvatarResponse },
    data () {
        return {
          tab: 0,
          isLoadingSession: false,
          isLoadingRepeat: false,
          stream: null,
          debug: '',

          knowledgeId: null,
          avatarId: 'ef08039a41354ed5a20565db899373f3',
          language: 'es',

          data: StartAvatarResponse,
          text: '',
          mediaStream: null,
          avatar: {current: null},
          chatMode: 'voice_mode',
          isUserTalking: false,
          sessionData: null,

        messages: [
          {"role": "system", "content": "Eres un asistente de salud de una aplicación llamada Aiduam. Tu género es femenino. En general, da respuestas cortas."},
          {"role": "system", "content": "Puedes entablar una comunicación con el usuario, trata de ser una compañía y guiarlo en temas de salud."},
			  ],
      }
    },
    methods: {
      async startSession() {
      try {
        await this.fetchAccessToken()
        this.isLoadingSession = true
        this.avatar.current = new StreamingAvatar({ token: this.token })

    this.avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log("Avatar started talking", e);
    });
    this.avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log("Avatar stopped talking", e);
    });
    this.avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log("Stream disconnected");
      this.endSession();
    });
    this.avatar.current.on(StreamingEvents.STREAM_READY, (event) => {
      console.log(">>>>> Stream ready:", event.detail);
      this.stream = event.detail
    });
    this.avatar.current.on(StreamingEvents.USER_START, (event) => {
      console.log(">>>>> User started talking:", event);
      this.isUserTalking = true
    });
    this.avatar.current.on(StreamingEvents.USER_STOP, (event) => {
      console.log(">>>>> User stopped talking:", event);
      this.isUserTalking = false
    });

    this.data = await this.avatar.current.createStartAvatar({
      avatarName: this.avatarId,
      quality: 'high',
      language: 'es',  
    })
    
    await this.avatar.startVoiceChat();
    } catch (error) {
      console.error("Error starting avatar session:", error);
    } finally {
      this.isLoadingSession  = false
    }
  },
    async handleSpeak(text) {
      this.isLoadingRepeat = true;
        if (!this.avatar.current) {
          this.debug = "Avatar API not initialized";
          return;
        }

      if (this.chatMode === 'voice_mode') {
        await this.avatar.current.startVoiceChat();
      }

      await this.avatar.current.speak({ text, task_type: TaskType.REPEAT }).catch((e) => {
        this.debug = e.message;
      });

    this.isLoadingRepeat = false;
    this.text = '';
},

/*   async  handleInterrupt() {
    if (!this.avatar.current) {
      this.debug = "Avatar API not initialized";

      return;
    }
    await this.avatar.current
      .interrupt()
      .catch((e) => {
        this.debug = e.message;
      });
  }, */

  async endSession() {
    await this.avatar.current.stopAvatar();
    this.stream = undefined;
  },

handleChangeChatMode(v) {
    if (v === 'text_mode') {
        this.avatar.current.closeVoiceChat();
    } else {
        this.avatar.current.startVoiceChat();
    }
    this.chatMode = v;
},
  async fetchAccessToken() {
    try {
      const response = await fetch("https://api.heygen.com/v1/streaming.create_token", {
        method: "POST",
        headers: {
        "x-api-key": process.env.VUE_APP_HEYGEN_API_KEY,
        "Content-Type": "application/json",
      },
      });
      const res = await response.json()
      const token = await res.data.token

      this.token = token

      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  },


  requestMessage(){
		return new Promise((resolve) => {
			if(this.text){
				const messages = [ ...this.messages, { role: "user", content: this.text }]
        this.messages = messages
				const data = {
					'model': 'gpt-4o-mini',
					'temperature': 0.2,
					'n': 1,
					'messages': messages
				}
				let config = {
					method: "post",
					maxBodyLength: Infinity,
					url: "https://api.openai.com/v1/chat/completions",
					headers: {
						'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
						'Content-Type': 'application/json'
					},
					data,
					withCredentials: false
				};

				console.log('Data: ', data)
		
				axios(config)
							.then((response) => {
								this.messages.push(response.data.choices[0].message)
                this.handleSpeak(response.data.choices[0].message.content)
							})
							.catch((error) => {
								console.log('Error: ', error)
								resolve({error})
							})
			}
		})
	}
  
},
watch: {
  stream: {
      immediate: true,
      handler(newStream) {
        if (newStream && this.$refs.mediaStream) {
          this.$refs.mediaStream.srcObject = newStream;
          this.$refs.mediaStream.onloadedmetadata = () => {
            this.$refs.mediaStream.play();
            this.debug = 'Playing';
          };
        }
      },
    },
  },
  tab(mode) {
    if (mode == 0) {
      this.chatMode == 'voice_mode'
    } else {
      this.chatMode == 'text_mode'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>